import {
  SET_RUNTIME_VARIABLE,
  SET_RUNTIME_VARIABLES,
  FETCH_RUNTIME_ADMIN_VARIABLES,
  FETCH_RUNTIME_ADMIN_VARIABLES_FAILURE,
  INCREMENT_RUNTIME_VARIABLE,
  FETCH_RUNTIME_ADMIN_TIMERS,
  FETCH_RUNTIME_ADMIN_TIMERS_FAILURE,
} from 'constants/redux/index';

const initialState = {
  currentSection:        null,
  CSR:                   false,
  pageIsLoaded:          false,
  pageNumber:            1,
  page:                  '',
  pagePrefix:            '',
  screen:                'normal',
  screenTypeReady:       false,
  weatherText:           '',
  weatherAdditionalText: '',
  weatherTemp:           '',
  weatherIcon:           '',
  isCityChanging:        false,
  adminData:             {
    variables: {
      anti_adblock:           false,
      promovidzhet_isrussian: false,
    },
    timers: {},
  },
  routingEnded:   false,
  isNewDesign:    false,
  geoid:          null,
  geoidAuto:      null,
  billboardSplit: 'main',
  ruid:           '',
  cityPeriod:     '',
  isRussia:       false,
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case SET_RUNTIME_VARIABLES:
      return {
        ...state,
        ...action.payload,
      };
    case INCREMENT_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: state[action.payload.name] + 1,
      };
    case FETCH_RUNTIME_ADMIN_VARIABLES:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          variables: {
            ...state.adminData.variables,
            [action.payload.codename]: action.payload.enabled,
          },
        },
      };
    case FETCH_RUNTIME_ADMIN_VARIABLES_FAILURE:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          variables: initialState.adminData.variables,
        },
      };

    case FETCH_RUNTIME_ADMIN_TIMERS:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          timers: action.payload,
        },
      };

    case FETCH_RUNTIME_ADMIN_TIMERS_FAILURE:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          timers: initialState.adminData.timers,
        },
      };
    default:
      return state;
  }
}
